import { Page } from '../models';

export const mapToPageModel = (nodePage: {
  id: string;
  title: string;
  body: { processed: string };
  relationships?: {
    field_bestanden: {
      localFile: {
        publicURL: string;
        name: string;
        extension: string;
        size: number;
      };
    }[];
  };
}): Page => ({
  id: nodePage.id,
  title: nodePage.title,
  body: nodePage.body.processed,
  files: nodePage.relationships?.field_bestanden.map(
    (field_bestand) => ({
      name: field_bestand.localFile.name,
      path: field_bestand.localFile.publicURL,
      extension: field_bestand.localFile.extension,
      size: field_bestand.localFile.size,
    })
  ) || [],
});
