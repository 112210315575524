import React from 'react';
import { graphql } from 'gatsby';
import { Page } from '../models';
import { mapToPageModel } from '../mappers/page.mapper';
import { formatBytes } from '../utils/bytes';
import { useData } from '../contexts/useData';
import { Helmet } from 'react-helmet';
import favicon from '../images/favicon.ico';

const Survey = ({ data: nodePage }) => {
  const pageData: Page = mapToPageModel(nodePage.nodePage);

  const { isMobile, isBigScreen, isDesktop } = useData();

  return (
    <section className="vl-region">
      <Helmet>
        <title>Survey | Barometer samenleven</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <div className="vl-layout">
        <h1 className="vl-title vl-title--h2">{pageData.title}</h1>
        <p
          className={`vl-u-spacer-bottom--medium vl-typography ${
            (isBigScreen || isDesktop) && 'vl-col--8-12'
          }`}
          dangerouslySetInnerHTML={{ __html: pageData.body }}
        />
        <div className="vl-grid vl-grid--is-stacked">
          {pageData.files.map((file, i) => (
            <div key={i} className={isMobile ? 'vl-col--1-1' : 'vl-col--4-12'}>
              <a href={file.path} target="_blank" className="vl-spotlight">
                <article role="none">
                  <div className="vl-spotlight__content">
                    <div className="vl-document">
                      <div className="vl-document__type">
                        <i
                          className="vl-vi vl-vi-document"
                          aria-hidden="true"
                        ></i>
                        <span className="vl-document__type__text">
                          {file.extension}
                        </span>
                      </div>
                      <div className="vl-document__content">
                        <h3 className="vl-document__title">{file.name}</h3>
                        <p className="vl-document__metadata">
                          {file.extension}-{formatBytes(file.size, 0)}
                        </p>
                      </div>
                    </div>
                  </div>
                </article>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Survey;

export const query = graphql`
  query getNodePageSurveys {
    nodePage(id: { eq: "2c3deaf0-f835-5f6f-9374-4ab69137f40b" }) {
      body {
        processed
      }
      title
      id
      relationships {
        field_bestanden {
          localFile {
            publicURL
            name
            extension
            size
          }
        }
      }
    }
  }
`;
